import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, colors, spacing } from '../../../../utils/styleguide';
import Image from '../../../contentful-elements/Image';
import Typography from '../../text/Typography';
import ColorComponent from '../../types/ColorComponent';
import { Col, Row } from '../12ColGridLayout';

const RowSection = styled(Row.withComponent('section'))`
  padding: ${spacing[10]}px 0;
  ${breakpoints.desktop} {
    padding: ${spacing[11]}px 0;
  }
`;

const ColTitle = styled(Col)`
  display: flex;
  margin-bottom: ${spacing[6]}px;
  ${breakpoints.desktop} {
    margin-bottom: ${spacing[10]}px;
  }
`;

const SectionsWrapper = styled(Row)`
  margin: 0;
  row-gap: ${spacing[6]}px;
  ${breakpoints.tablet} {
    margin: 0;
  }
`;

const HorizontalListItem = styled(Col)`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const ItemTitle = styled(Typography)`
  margin: ${spacing[4]}px 0 ${spacing[3]}px;
  ${breakpoints.desktop} {
    margin: ${spacing[4]}px 0;
  }
`;

const ContentWrapper = styled(Typography)`
  margin-bottom: ${spacing[2]}px;
`;

const LogosWrapper = styled.div`
  display: flex;
  margin: ${spacing[2]}px 0;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${spacing[6]}px;
  row-gap: ${spacing[2]}px;
  ${breakpoints.tablet} {
    gap: ${spacing[4]}px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: flex-end;
  margin-top: ${spacing[2]}px;
  ${breakpoints.desktop} {
    margin-top: ${spacing[4]}px 0;
  }
`;

type Props = {
  Title?: ColorComponent;
  deprecated_withIcons?: boolean; // deprecated
  sections: {
    Image?: typeof Image;
    title: JSX.Element | string;
    content: JSX.Element | string;
    maxMediaWidth?: number;
    Logos?: JSX.Element[];
    Button?: JSX.Element;
    isIcon?: boolean;
  }[];
};

const HorizontalListLayout = ({ Title, sections, deprecated_withIcons }: Props) => {
  return (
    <RowSection>
      {Title && (
        <ColTitle>
          <Title color={colors.darkGrey} />
        </ColTitle>
      )}
      <Col>
        <SectionsWrapper>
          {sections?.map(
            ({ Image, title, content, maxMediaWidth, Logos, Button, isIcon }, index) => (
              <HorizontalListItem key={index} tablet="span 4">
                {Image &&
                  (deprecated_withIcons || isIcon ? (
                    <Image
                      wrapperCss={css`
                        margin: 0;
                        width: 80px;
                      `}
                      spans={{ tablet: 3, desktop: 3 }}
                      maxWidth={80}
                      fitWrapper
                    />
                  ) : (
                    <Image
                      wrapperCss={css`
                        width: ${maxMediaWidth}px;
                        flex-grow: 0;
                      `}
                      spans={{ tablet: 3, desktop: 3 }}
                      maxWidth={maxMediaWidth}
                      fitWrapper
                    />
                  ))}
                {typeof title === 'string' ? <ItemTitle variant="h4">{title}</ItemTitle> : title}
                <ContentWrapper tag="div">{content}</ContentWrapper>
                {Logos && <LogosWrapper>{Logos}</LogosWrapper>}
                {Button && <ButtonWrapper>{Button}</ButtonWrapper>}
              </HorizontalListItem>
            ),
          )}
        </SectionsWrapper>
      </Col>
    </RowSection>
  );
};

export default HorizontalListLayout;
